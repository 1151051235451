(function() { 'use strict';
  // Selectors
  // ---------
  window.$WINDOW = $(window);
  window.$DOCUMENT = $(document);


  // Masked input
  // ------------
  $('input[type="date"]').attr('type', 'text');

  // Phone
  $('input[type="tel"]').inputmask({
    regex: '\\+7 \\([0-6,9]\\d\\d\\) \\d{3}(\\-\\d{2}){2}',
    placeholder: '+7 (___) ___-__-__'
  });

  // Email
  $("form input[name*=\"email\"]").inputmask({
    mask: "*{+}@*{+}",
    greedy: false,
    onBeforePaste: function (pastedValue, opts) {
      pastedValue = pastedValue.toLowerCase();
      return pastedValue.replace("mailto:", "");
    },
    definitions: {
      '*': {
        validator: "[.0-9A-Za-z!#$%&'*+/=?^_`{|}~-]",
        cardinality: 1,
        casing: "lower"
      }
    }
  });


  // Variables
  // ---------
  var cycleIsActive = false,
      sliderInterval = null;


  $DOCUMENT
    .ready(function() {
      // Header slider
      // -------------
      $('.slider_text')
        .cycle({
          log: false,
          autoHeight: false,
          fx: "scrollHorz",
          slides: "> div.item",
          pauseOnHover: true,
          pagerTemplate: "<span></span>",
          speed: 800,
          timeout: 0,
          updateView: 1
        })
        .hover(
        function() {
          if(sliderInterval !== null) clearInterval(sliderInterval);
        },
        function() {
          setSliderInterval();
        })
        .on('click', '.cycle_next', function() {
          if( !cycleIsActive ) $('.slider_text').cycle('next');
        })
        .on('click', '.cycle_prev', function() {
          if( !cycleIsActive ) $('.slider_text').cycle('prev');
        })
        .on('cycle-before', function() {
          cycleIsActive = true;
        })
        .on('cycle-after', function() {
          cycleIsActive = false;
        })
        .on('cycle-next', function() {
          $('.slider_img').cycle('next');
        })
        .on('cycle-prev', function() {
          $('.slider_img').cycle('prev');
        });

      $('.slider_img').cycle({
        log: false,
        autoHeight: false,
        fx: "scrollHorz",
        slides: "> div",
        pauseOnHover: false,
        pagerTemplate: "<span></span>",
        speed: 800,
        timeout: 0,
        updateView: 1
      });

      function setSliderInterval() {
        if($('.slider_text > div').length > 1)
          sliderInterval = setInterval(function() { $('.slider_text').cycle('next') }, 5000);
      }
      setSliderInterval();


      // Portolio sliders
      // ----------------
      $('.portfolio-cycle-container')
        .on('cycle-next cycle-prev', '.portfolio_main_cycle .cycle-slideshow', function(e, opts) {
          $(this).parents('.portfolio-cycle-container').find('.portfolio_main_cycle_pager .cycle-slideshow').cycle('goto', opts.currSlide);
        })
        .on('cycle-pre-auto-next', '.portfolio_main_cycle .cycle-slideshow', function(e, opts) {
          $(this).parents('.portfolio-cycle-container').find('.portfolio_main_cycle_pager .cycle-slideshow').cycle('goto', opts.nextSlide);
        })
        .on('cycle-next cycle-prev', '.portfolio_main_cycle_pager .cycle-slideshow', function(e, opts) {
          $(this).parents('.portfolio-cycle-container').find('.portfolio_main_cycle .cycle-slideshow').cycle('goto', opts.currSlide);
        })
        .on('click', '.portfolio_main_cycle_pager .cycle-slide', function() {
          var $slideshow = $(this).parents('.cycle-slideshow'),
              slideIndex = $slideshow.data('cycle.API').getSlideIndex(this);

          $slideshow.cycle('goto', slideIndex);
          $(this).parents('.portfolio-cycle-container').find('.portfolio_main_cycle .cycle-slideshow').cycle('goto', slideIndex);
        });


      // Fancybox
      // --------
      $('.phone_order, .fancybox').fancybox({
        width: 'auto',
        height: 'auto',

        minWidth: 0,
        minHeight: 0,

        padding: 0,

        autoSize: false,

        fitToView: true,

        scrolling: 'no',

        closeBtn: true,
        closeClick: false,
        nextClick: false,
        modal: false,
        loop: true,

        openEffect: 'none',
        closeEffect: 'none',

        tpl: {
          closeBtn: '<a title="Закрыть" class="fancybox-item fancybox-close curp_a" href="javascript:;" style="background-image: none;"><div class="btn btn-close btn-45deg bgc0"></div></a>',
          prev: '<a title="Предыдущий слайд" class="fancybox-nav fancybox-prev curp_a" href="javascript:;"><div class="btn btn-prev btn-45deg"></div></a>',
          next: '<a title="Следующий слайд" class="fancybox-nav fancybox-next curp_a" href="javascript:;"><div class="btn btn-next btn-45deg"></div></a>'
        },

        helpers: {
          overlay: {
            closeClick: true,
            speedOut: 0,
            showEarly: true,
            locked: true
          }
        },

        beforeLoad: function() {
          this.title = $(this.element).attr('caption');
        },

        beforeShow: function () {
          $.fancybox.wrap.bind('contextmenu', function (e) {
            return false;
          });
        }
      });
    });
})();
